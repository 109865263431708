<template>
  <div :class="isDialog ? 'my-source-page100' : 'my-source-page'">
    <div v-if="!isFail" class="source-page">
      <div class="source-page-title" v-if="titleShow">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="合格证" name="product0" ></el-tab-pane>
          <el-tab-pane label="产品信息" name="product1"></el-tab-pane>
          <el-tab-pane label="检测报告" v-if="showHide.product2" name="product2"></el-tab-pane>
          <el-tab-pane label="生产记录" v-if="showHide.product3" name="product3"></el-tab-pane>
          <el-tab-pane label="主体信息" name="product4"></el-tab-pane>
          <el-tab-pane label="主体资质" v-if="showHide.product5" name="product5"></el-tab-pane>
        </el-tabs>
      </div>
      <div :class="titleShow ? 'source-content menu-source-content' : 'source-content nor-source-content'" id="source-content">
        <div id="product0">
          <img class="img100 mb10" :src="require('@/assets/images/source1.png')" alt="">
          <el-image 
            id="tempImg"
            class="temp-img"
            :src="temImg" 
            :preview-src-list="[temImg]">
          </el-image>
          <div class="source-item source-item1">
            <div class="item-content item1-left">
              <div>编号：{{ contObj.certificateOfComplianceBathCode || ''}}</div>
              <div>查询次数：{{ contObj.searchCountInt || ''}}</div>
              <div>首查时间：{{ contObj.firstQueryTime || ''}}</div>
              <div>产品名称：{{ contObj.productName || ''}}</div>
              <div>开证时间：{{ contObj.issueTime || ''}}</div>
              <div>数量：{{ (contObj.allCount || '') + (contObj.countUnit || '') + '，每' + (contObj.countUnit || '') + (contObj.numallWeight || '')}} </div>
              <div>总重量：{{ (contObj.allWeight || '') + (contObj.weightUnit || '')}}</div>
              <div>联系方式：{{ contObj.linkmanPhoneNumber || ''}}</div>
              <div>产地：{{ contObj.productionAddress || ''}}</div>
              <div>开证者：{{ contObj.bodyName || ''}}</div>
              <div>承诺依据：{{ contObj.gistNumValue || ''}}</div>
            </div>
            <div class="item1-right">
              <div class="item1-r-b">
                <el-image 
                  id="previewPic"
                  class="imgi1"
                  :src="contObj.cscImageUrl" 
                  :preview-src-list="[contObj.cscImageUrl]">
                </el-image>
                <img class="imgi4" @click="previewPic" :src="require('@/assets/images/source5.png')" alt="">
                <img class="imgi2" @click="savePic" :src="require('@/assets/images/source4.png')" alt="">
              </div>
              <div class="item1-r-t" @click="onBuy">
                <img class="imgi3" :src="require('@/assets/images/source2.png')" alt="">
                <div class="item1-red">在线订购</div>
              </div>
            </div>
          </div>
          <!-- <div class="item1-part">
              产品满意度：
              <el-rate
                v-model="satisfactionLevel.satisfactionLevel"
                @change="onfieldChange"
                :disabled="!hasOpenId"
                disabled-void-color="#C0C4CC">
              </el-rate>
          </div> -->
        </div>

        <div id="product1" class="source-item mt10">
          <div class="item-title">
            <el-divider content-position="center" class="">产品信息</el-divider>
          </div>
          <div class="pics-switer" v-if="contObj.productUrl && contObj.productUrl.length">
            <el-carousel trigger="click" autoplay :interval="2000" arrow="never" height="180px">
              <el-carousel-item v-for="item in contObj.productUrl" :key="item">
                <img @click="carouselClick(item)" class="item" :src="item" alt="">
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="item-content">
            <div>产品名称：{{ contObj.productName || ''}}</div>
            <div>产品条码：{{ contObj.productCode || ''}}</div>
            <div>产地：{{ contObj.productionAddress || ''}}</div>
            <div>生产基地：{{ contObj.baseName || ''}}</div>
            <div>生产规模：{{ contObj.baseScale || ''}}</div>
            <div>产品介绍：{{ contObj.productIntroduce || ''}}</div>
          </div>
        </div>

        <div id="product2" class="source-item mt10" v-if="showHide.product2">
          <div class="item-title">
            <el-divider content-position="center" class="">检测报告</el-divider>
          </div>
          <div class="item-label">自检报告：</div>
          <div class="pics-switer" v-if="contObj.inspectionReportUrl && contObj.inspectionReportUrl.length">
            <el-carousel trigger="click" autoplay :interval="2000" arrow="never" height="180px">
              <el-carousel-item v-for="item in contObj.inspectionReportUrl" :key="item">
                <img class="item" @click="carouselClick(item)" :src="item" alt="">
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="no-data gray" v-else> 暂无数据 </div>
          <div class="item-label">委托检测报告：</div>
          <div class="pics-switer" v-if="contObj.thirdInspectionReportUrl && contObj.thirdInspectionReportUrl.length">
            <el-carousel trigger="click" autoplay :interval="2000" arrow="never" height="180px">
              <el-carousel-item v-for="item in contObj.thirdInspectionReportUrl" :key="item">
                <img class="item" @click="carouselClick(item)" :src="item" alt="">
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="no-data gray" v-else>暂无数据</div>
        </div>

        <div id="product3" class="source-item mt10 product4" v-if="showHide.product3">
          <div class="item-title">
            <el-divider content-position="center" class="">生产记录</el-divider>
          </div>
          <div
            v-for="(item, key) in contObj.farmingManagementVoList" 
            :key="key"
            class="item-cont"
          >
            <div class="item-label flex-two-part">
              <div class="item-size1">{{ item.managementName || '' }}</div>
              <div class="item-size2">{{ item.farmingDate || '' }}</div>
            </div>
            <!-- <div class="item-size3">{{ item.worker || '' }}</div> -->
            <div class="pics-switer" v-if="item.imageUrl && item.imageUrl.length">
              <el-carousel trigger="click" autoplay :interval="2000" arrow="never" height="180px">
                <el-carousel-item v-for="item in item.imageUrl" :key="item">
                  <img class="item" @click="carouselClick(item)" :src="item" alt="">
                </el-carousel-item>
              </el-carousel>
            </div>
            <!-- <div class="pics-switer" wx:if="{{ item.videoUrl.length }}">
              <block wx:for="{{ item.videoUrl }}" wx:key="*this" wx:for-item="item2">
                <video src="{{ item2.url }}" class="source-video"></video>
              </block>
            </div> -->
            <div class="pics-switer" v-if="item.videoUrl && item.videoUrl.length">
              <div class="all-pics" v-for="(vid, index) in item.videoUrl" :key="index">
                <video :src="vid" class="avatar" controls="controls">您的浏览器不支持视频播放</video>
              </div>
            </div>
          </div>
          <div class="no-data gray" v-if="!(contObj.farmingManagementVoList && contObj.farmingManagementVoList.length)">暂无数据</div>
        </div>

        <div id="product4" class="source-item mt10">
          <div class="item-title">
            <el-divider content-position="center" class="">主体信息</el-divider>
          </div>
          <div class="item-content mb10">
            <div>主体名称：{{ contObj.bodyName || ''}}</div>
            <div>企业法人：{{ contObj.representativeName || ''}}</div>
            <div>联系电话：{{ contObj.linkmanPhoneNumber || ''}}</div>
            <div>联系地址：{{ contObj.businessAddress || ''}}</div>
            <div>电子邮箱：{{ contObj.email || ''}}</div>
            <div>企业网址：{{ contObj.bodyUrl || ''}}</div>
            <div>企业介绍：{{ contObj.bodyAbstract || ''}}</div>
          </div>
          <div class="pics-switer" v-if="contObj.productUrl2 && contObj.productUrl2.length">
            <el-carousel trigger="click" autoplay :interval="2000" arrow="never" height="180px">
              <el-carousel-item v-for="item in contObj.productUrl2" :key="item">
                <img class="item" @click="carouselClick(item)" :src="item" alt="">
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>

        <div id="product5" class="source-item mt10" v-if="showHide.product5">
          <div class="item-title">
            <el-divider content-position="center" class="">主体资质</el-divider>
          </div>
          <div
            v-for="(item, key) in contObj.unitQualificationFileVoList" 
            :key="key"
            class="item-cont"
          >
            <div class="free-certificate-list">
              <div class="fc-img">
                <my-image 
                  class="fc-img"
                  :src="item.unitQualificationFileUrl" 
                  :preview-src-list="[item.unitQualificationFileUrl]">
                </my-image>
              </div>
              <div class="fc-item">
                <div class="fcd-detail">证书名称：{{ item.unitQualificationFileName || '' }}</div>
                <div class="fcd-detail">颁发单位：{{ item.issueUnit || '' }}</div>
                <div class="fcd-detail">颁发时间：{{ item.issueTime || '' }}</div>
              </div>
            </div>
            <el-divider></el-divider>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="no-data2 gray">{{ errorMsg }}</div>
  </div>
</template>
<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
export default {
  name: "sourceCont",
  components: {
  },
  props:{
    code: {
      type: String,
      default: "",
    },
    isDialog: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {   
      contObj: {
        productUrl: [],
        inspectionReportUrl: [],
        thirdInspectionReportUrl: [],
        farmingManagementVoList: [],
        unitQualificationFileVoList: [],
        productUrl2: [],
        searchCountInt: '',
        firstQueryTime: '',
        productName: '',
        issueTime: '',
        allWeight: '',
        weightUnit: '',
        linkmanPhoneNumber: '',
        productionAddress: '',
        representativeName: '',
        gistNumValue: '',
        cscImageUrl: '',
        satisfactionLevel: 0,
        productName: '',
        productCode: '',
        productionAddress: '',
        baseName: '',
        baseScale: '',
        productIntroduce: '',
        bodyName: '',
        representativeName: '',
        linkmanPhoneNumber: '',
        businessAddress: '',
        email: '',
        bodyUrl: '',
        bodyAbstract: '',

      },
      certificateOfComplianceId: '',
      activeName: 'product0',
      dom: null,
      titleShow: false,
      isFail: false,
      errorMsg: '',
      satisfactionLevel: {
        satisfactionLevelId: '',
        satisfactionLevel: 0
      },
      hasOpenId: false,
      showHide: {
        product2: true,
        product3: true,
        product5: true,
      },
      temImg: ''
    };
  },
  created() {
	},
  mounted(){
    if(this.code){
      this.initData(this.code)
    }
    if(utils.getOpenId()){
      this.hasOpenId = true
    }
  },
  destroyed() {
    this.dom?.removeEventListener("scroll", this.scrollFun, true);
  },
  methods: {
    initData(code){
      this.getInfo(code)
      this.$nextTick(() => {
        this.getScroll()
      })
    },
    getScroll(){
      let dom = document.getElementById('source-content')
      this.dom = dom
      dom.addEventListener("scroll", this.scrollFun, true)
    },
    destroyScroll(){
      this.dom?.removeEventListener("scroll", this.scrollFun, true);
    },
    setActiveName(scrollTop){
      let { product2, product3, product5} = this.showHide
      this.titleShow = scrollTop >= 10 ? true : false
      if(product5 && scrollTop >= this.getScrollHeight('product5')){
        this.activeName = 'product5'
      }else if(scrollTop >= this.getScrollHeight('product4')){
        this.activeName = 'product4'
      }else if(product3 && scrollTop >= this.getScrollHeight('product3')){
        this.activeName = 'product3'
      }else if(product2 && scrollTop >= this.getScrollHeight('product2')){
        this.activeName = 'product2'
      }else if(scrollTop >= this.getScrollHeight('product1')){
        this.activeName = 'product1'
      }else{
        this.activeName = 'product0'
      }
    },
    getScrollHeight(name){
      return document.getElementById(name) ? document.getElementById(name).offsetTop - this.dom.offsetTop : 0
    },
    scrollFun(e){
      this.setActiveName(e.target.scrollTop || 0)
    },
    handleClick(tab){
      this.dom.scrollTop = this.getScrollHeight(tab.name || 'product0')
    },
    async getInfo(code){
      let _this = this
      this.isFail = false
      request.trackingproductfile({
        procurementCode: code,
        openId: '',
        scanIp: ''
      }).then(res => {
        if(res.code == 200){
          let result = res.data,
            obj = Object.assign({}, result),
            realVal = ''
          if(!result){
            _this.isFail = true
            _this.errorMsg = '暂无数据！'
          }
          if(result.gistNum1 == '01'){
            realVal += '质量安全控制符合要求；'
          }
          if(result.gistNum2 == '01'){
            realVal += '自行检测合格；'
          }
          if(result.gistNum3 == '01'){
            realVal += '委托检测合格；'
          }
          let lists = result.unitQualificationFileVoList || []
          lists = lists.map(cur => {
            return {
              ...cur,
              unitQualificationFileUrl: cur.unitQualificationFileUrl ? cur.unitQualificationFileUrl.split(',')[0] : '',
            }
          })

          let lists2 = result.farmingManagementVoList || [],
            list3 = []
          lists2.forEach(cur => {
            // if(cur.show){
              list3.push({
                ...cur,
                imageUrl: cur.imageUrl ? cur.imageUrl.split(',') : [],
                videoUrl: cur.videoUrl ? cur.videoUrl.split(',') : [],
                farmingDate: utils.dateFormat(cur.farmingDate, 'yyyy-MM-dd')
              })
            // }
          })
          obj = {
            ...obj,
            numallWeight: result.allCount ? utils.getFixedNumber(result.allWeight / result.allCount) + (result.weightUnit || '') : 0 + (result.weightUnit || ''),
            satisfactionLevel: result.satisfactionLevel || 0,
            gistNumValue: realVal,
            unitQualificationFileVoList: lists,
            farmingManagementVoList: list3,
            productUrl2: result.productUrl2 ? result.productUrl2.split(',') : [],
            productUrl: result.productUrl ? result.productUrl.split(',') : [],
            inspectionReportUrl: result.inspectionReportUrl ? result.inspectionReportUrl.split(',') : [],
            thirdInspectionReportUrl: result.thirdInspectionReportUrl ? result.thirdInspectionReportUrl.split(',') : []
          }
          console.log(obj)
          _this.contObj = obj
          _this.certificateOfComplianceId = obj.certificateOfComplianceId
          if(_this.isDialog){
            _this.setHideShow()
          }
          setTimeout(() => {
            _this.getcscimageurl()
          }, 2000);
        }else{
          _this.isFail = true
          _this.errorMsg = res.msg || '暂无数据！'
          _this.message(res.msg || '', "error")
        }
      }).catch(e => {
        _this.errorMsg = '数据获取出错，请稍后重试'
        _this.isFail = true
      })

      // let levelData = await utils.getUrlDatas('findSatisfaction', {
      //   certificateOfComplianceId: code || '',
      //   wxCode: utils.getOpenId() || ''
      // })
      // if(levelData){
      //   this.satisfactionLevel.satisfactionLevel = parseInt(levelData.satisfactionLevel)
      // }
    },
    setHideShow(){
      let contObj = this.contObj,
        product2 = true,
        product3 = true,
        product5 = true
      if(!contObj.inspectionReportUrl.length && !contObj.thirdInspectionReportUrl.length){
        product2 = false
      }
      if(!contObj.farmingManagementVoList.length){
        product3 = false
      }
      if(!contObj.unitQualificationFileVoList.length){
        product5 = false
      }
      this.showHide = {
        product2: product2,
        product3: product3,
        product5: product5,
      }
    },
    getcscimageurl(fn){
      let _this = this
      request.findcscimageurl({
        certificateOfComplianceId: _this.certificateOfComplianceId
      }).then(res => {
        if(res.code == 200){
          let cscImageUrl = res.data?.cscImageUrl || ''
          if(cscImageUrl){
            _this.contObj.cscImageUrl = cscImageUrl
          }
          if(fn){
            fn(res.data.cscPdfUrl || '')
          }
        } else {
          _this.message(res.msg || '', "error")
        }
      }).catch(e => {
        console.log(e)
      })
    },
    async onfieldChange(val){
      let data = await utils.postRequestData('trackingsatisfaction', {
        certificateOfComplianceId: this.code || '',
        procurementCode: '',
        wxName: '',
        satisfactionLevel: val || '',
        wxCode: utils.getOpenId() || '',
      })
      if(data){
        this.message('评价成功！', "success")
      }
    },
    previewPic(){
      document.getElementById('previewPic').click()
    },
    carouselClick(item){
      this.temImg = item
      setTimeout(() => {
        let dom = document.getElementById('tempImg')
        dom && dom.click()
      }, 0)
    },
    savePic(){
      let _this = this
      this.getcscimageurl((url) => {
        utils.downloadUrl(url, '承诺达标合格证.pdf')
      })
    },
    downloadIamge(imgsrc, name) {
      let image = new Image()
      image.setAttribute("crossOrigin", "anonymous")
      image.onload = function() {
        let canvas = document.createElement("canvas")
        canvas.width = image.width
        canvas.height = image.height
        let context = canvas.getContext("2d")
        context.drawImage(image, 0, 0, image.width, image.height)
        let url = canvas.toDataURL("image/jpg") 
        let a = document.createElement("a")
        let event = new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window
        })
        a.download = name || "photo" 
        a.href = url 
        a.dispatchEvent(event) 
      }
      image.src = imgsrc
    },
    onBuy(){
      this.$alert('在“产品信息”中设置“销售网址”，消费者可以购买您“销售网址”链接店铺的产品！', '提示', {
        confirmButtonText: '确定',
        callback: () => {
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.my-source-page{
  height: 100%;
  overflow-y: auto;
  background-color: #f0f3f6;
}
.my-source-page100{
  height: 100vh;
  overflow-y: auto;
  background-color: #f0f3f6;
}
.source-title{
  background-color: #fff;
  height: 40px;
}
.line10{
  padding-top: 10px;
  background-color: #f0f3f6;
}
.source-page-title{
  background-color: #fff;
}
.source-page-title ::v-deep .el-tabs__header{
  margin: 0;
}
.source-content{
  overflow-y: auto;
  background-color: #f0f3f6;
  padding: 10px;
}
.nor-source-content{
  max-height: calc(100% - 20px);
}
.menu-source-content{
  max-height: calc(100% - 65px);
}
.img100{
  width: 100%;
  height: 120px;
  margin-bottom: 6px;
}
.img100-2{
  width: 100%;
}
.mb10{
  margin-bottom: 10px;
}
.mt10{
  margin-top: 10px;
}
.ml5{
  margin-left: 5px;
}
.source-item{
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 10px 0;
}
.source-item1{
  display: flex;
  justify-content: space-between;
}
.item1-left{
  // width: 80%;
}
.item1-right{
  flex-grow: 0;
  flex-shrink: 0;
}
.item-content > div{
  padding: 5px 10px;
  word-wrap: break-word;
  word-break: break-all;
  font-size: 14px;
  line-height: 26px;
}
.rich-texts{
  padding: 0 10px;
  padding-bottom: 5px;
}
.font14{
  font-size: 14px;
}
.item1-r-b{
  border-radius: 10px;
  border: 2px solid #f0f3f6;
  width: 110px;
  margin-right: 7px;
  padding: 10px;
  text-align: center;
}
.imgi1{
  width: 110px;
  height: 120px;
}
.imgi4{
  width: 110px;
  height: 30px;
  cursor: pointer;
}
.imgi2{
  width: 110px;
  height: 33px;
  cursor: pointer;
}
.item1-r-t{
  border-radius: 10px;
  border: 2px solid #f0f3f6;
  width: 110px;
  margin-top: 10px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}
.imgi3{
  width: 60px;
  height: 60px;
}
.item1-red{
  color: red;
  font-weight: bolder;
}
.item1-part{
  display: flex;
  align-items: center;
  font-size: 15px;
  padding: 10px;
}
.item-title{
  width: 90%;
  padding-left: 5%;
}
.item-size1{
  font-size: 14px;
  font-weight: bolder;
}
.item-size2{
  font-size: 13px;
  color: gray;
}
.item-size3{
  font-size: 14px;
  font-weight: bolder;
  text-align: center;
  padding-bottom: 5px;
}
.item-label{
  font-size: 15px;
  padding: 10px;
}
.flex-two-part{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.free-certificate-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 0 10px;
}
.fc-img{
  width: 100px;
  height: 100px;
}
.fc-item{
  align-self: baseline;
  width: 600px;
  margin-top: 10px;
}
.gray {
  color: rgb(150, 149, 147);
  font-size: 14px;
}
.item-cont{
  padding-bottom: 10px;
}
.fcd-detail-title{
  color: #19191a;
  font-size: 16px;
  padding-left: 12px;
  padding-bottom: 4px;
}
.fcd-detail{
  color: #636466;
  font-size: 14px;
  padding-left: 12px;
  padding-bottom: 12px;
}
.pics-switer{
  padding: 10px;
}
.pics-switer-img{
  border-radius: 10px;
}
.no-data{
  font-size: 14px;
  text-align: left;
  padding: 15px;
}
.no-data2{
  font-size: 14px;
  text-align: center;
  padding: 20px;
  background-color: #fff;
  height: 100%;
  box-sizing: border-box;
}
.swiper-imgs{
  width: 100%;
  height: 100%;
}
.source-video{
  width: 100%;
}
.product4-s {
  height: 250px;
}
.share-btn button{
  border: none;
  background-color: #f0f3f6;
  font-size: 14px;
  padding: 0;
  width: 10px;
  color: #1296db;
}
.all-pics {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 125px;
  height: 120px;
  margin: 0 8px 8px 0;
  display: inline-block;
}
.avatar {
  width: 100%;
  height: 148px;
  display: block;
}
.item{
  width: 100%;
  height: 180px;
}
.pics-switer ::v-deep .el-carousel__button{
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.source-page{
  height: 100%;
}
.source-page-title ::v-deep .el-tabs__nav-next, .source-page-title ::v-deep .el-tabs__nav-prev{
  display: none;
  // opacity: 0;
}
.source-page-title ::v-deep .el-tabs__nav-scroll{
  overflow: auto;
}
.source-page-title ::v-deep .el-tabs__item.is-active{
  color: #2979f8
}
.source-page-title ::v-deep .el-tabs__active-bar{
  background-color: #0000ff;
}
</style>


